<template>
  <div class="wrapper pageBox">
    <div class="banner">
      <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/banner.png">
      <div class="bannerText">
        <div class="title">联系我们</div>
        <div class="btn"><div class="company">中嘉数科</div><div class="colLine" />星辰大海，等你前来</div>
      </div>
    </div>
    <div class="connect">
      <div class="topText">
        <div class="title">联系方式</div>
      </div>
      <div class="conBox">
        <div class="leftText">
          <div class="li">
            <div class="label">联系电话</div>
            <div class="value">
              <img src="@/assets/about/phone.svg">
              <span>0571-82650015</span>
            </div>
          </div>
          <div class="li">
            <div class="label">商务咨询</div>
            <div class="value">
              <img src="@/assets/about/phone.svg">
              <span>0571-82650015</span>
            </div>
          </div>
          <div class="li">
            <div class="label">杭州总部</div>
            <div class="value">
              <img src="@/assets/about/address.svg">
              <span>浙江省杭州市萧山区经济技术开发区明星路371号2幢902-3室</span>
            </div>
          </div>
          <div class="li">
            <div class="label">鹰潭办事处</div>
            <div class="value">
              <img src="@/assets/about/address.svg">
              <span>江西省鹰潭市月湖区沿江大道7号清波雅苑</span>
            </div>
          </div>
          <div class="li">
            <div class="label">赣州办事处</div>
            <div class="value">
              <img src="@/assets/about/address.svg">
              <span>江西省赣州市章贡区水南镇长征大道31号商会大厦</span>
            </div>
          </div>
          <div class="li">
            <div class="label">联系邮箱</div>
            <div class="value">
              <img src="@/assets/about/email.svg">
              <span>info@zhongjiadata.com</span>
            </div>
          </div>
        </div>
        <div class="mapBox">
          <div id="container" />
          <div class="tips">杭州总部地图</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let map;
export default {
  data() {
    return {
      icon: require('../../assets/about/mark_b.png')
    };
  },
  mounted() {
    this.mapInit();
  },
  methods: {
    mapInit() {
      // eslint-disable-next-line
      map = new AMap.Map('container', {
        zoom: 18,
        zooms: [1, 18],
        center: [120.247471, 30.203608]
      });
      // eslint-disable-next-line
      const marker = new AMap.Marker ({
        // eslint-disable-next-line
        position: new AMap.LngLat(120.248812, 30.203538),
        map: map
      });
      map.add(marker);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  width: 268px !important;
  background-color: rgba(255, 255, 255, .15)!important;
  .company {
    color: #2C6EFF;
  }
}
.topText {
  height: 213px;
  // .title {
  //   padding-top: 80px;
  // }
}
.conBox {
  margin: 0 auto;
  margin-bottom: 288px;
  // margin-top: 50px;
  width: 1280px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .leftText {
    flex: 1;
    // margin-top: 46px;
    .li {
      .label {
        font-size: 15px;
        font-weight: 700;
        color: #252B3A;
      }
      .value {
        font-size: 15px;
        margin-top: 13px;
        margin-bottom: 32px;
        height: 22px;
        display: flex;
        align-items: center;
        color: #6A6E77;
        img {
          margin-right: 6px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .mapBox {
    margin-top: 7px;
    width: 642px;
    height: 384px;
    #container {
      width: 100%;
      height: 100%;
    }
    .tips {
      margin-top: 8px;
      font-size: 12px;
      color: #C8C8C8;
    }
  }
}
</style>
